
:root {
  --top-bar-height: 60px;
  //--max-stage-height: 560px;
  --max-stage-height: 100vh;
  @media screen and (min-width: 480px)
  {
    --top-bar-height: 74px;
  }
}


.jodit-toolbar-editor-collection {

  & > .jodit-ui-group  {
  }

  & > .jodit-ui-group ~ .jodit-ui-group  {
    display: none;
  }
}

body {
  overflow-x: hidden;
}